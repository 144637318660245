'format es6';
'use strict';

import 'babel-polyfill';
import $ from 'jquery';

import Scrollbar from 'smooth-scrollbar';
import imagesLoaded from 'imagesloaded';

import './utils/reportValidity';
import { docReady } from './utils/docReady';
import { Animator } from '../lagrange/animation/Animator';
import { Animations } from './Animations';
import { isNativeScrolling } from './AppSettings';
import { isMobile as checkMobile } from './utils/isMobile';
import { isEdge } from './utils/browserDetect';
import { objForeachPolyfill } from './utils/objectForeachPolyfill';

import WrapIframe from './WrapIframe';
import WriteDate from './WriteDate';
import Menu from './Menu';
import Video from './Video';
import VideoVisible from './VideoVisible';
import Forms from './Forms';
import Filters from './Filters';
import NewsletterPopin from './NewsletterPopin';
import Career from './Career';
import HalfHalf from './HalfHalf';
import LanguageSwitcher from './LanguageSwitcher';
import Anchors from './Anchors';
import TagManager from './TagManager';
import Timeline from './Timeline';

import ReservationForm from './ReservationForm';
import QuickReservation from './QuickReservation';
import GiftCard from './GiftCard';
import Collapser from './Collapser';

import Cart from './Cart';
import CartSidebar from './CartSidebar';
import CartIcon from './CartIcon';
import Checkout from './Checkout';
import AlertPopin from './AlertPopin';

let sb; //eslint-disable-line

objForeachPolyfill();

docReady.then(() => {
	if (isEdge([12, 13, 14])) {
		$('img').removeAttr('srcset').removeAttr('sizes');
	}
	
	const main = document.querySelector('[data-main-scrollbar]');
	
	if (main) {
		let animator = null;
		
		let isMobile = checkMobile(1024);
		animator = new Animator();
		
		const onResize = (e, first) => {
			if (checkMobile(1024) && !isMobile) {
				isMobile = true;
			} else if (!checkMobile(1024) && isMobile) {
				isMobile = false;
			}
			
			if (!first) {
				animator.setAnimations(Animations.get(isMobile));
			}
		};
		
		$(window).on('resize.skyspa.animator', onResize);
		onResize(null, true);
		
		const loadedImages = new Promise((resolve) => {
			imagesLoaded(document.body, { background: true }, resolve);
		});
		
		if (!isNativeScrolling && !isMobile) {
			main.setAttribute('data-has-scrollbar', true);
			
			const opts = {};
			
			if (!isMobile) {
				document.body.classList.add('has-scrollbar');
			} else if (checkMobile(1024)) {
				opts.damping = 1;
			}
			
			sb = Scrollbar.init(main, opts);
		}
		
		loadedImages.then(() => {
			animator.setAnimations(Animations.get(isMobile));
		});
	}
	
	// 2. This code loads the IFrame Player API code asynchronously.
	const tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';
	
	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	
	ReservationForm.init();
	QuickReservation.init();
	GiftCard.init();
	
	WriteDate.setup();
	WrapIframe.init();
	Menu.init();
	
	Video.init();
	VideoVisible.init();
	Forms.init();
	Filters.init();
	NewsletterPopin.init();
	Career.init();
	HalfHalf.init();
	LanguageSwitcher.init();
	Anchors.init();
	TagManager.init();
	Timeline.init();
	AlertPopin.init();
	Collapser.init();

	Cart.init();
	CartSidebar.init();
	CartIcon.init();
	Checkout.init();
	window.Cart = Cart;
	
}).catch((e) => { console.error(e); });
