'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
import Scrollbar from 'smooth-scrollbar';
import { isNativeScrolling } from './AppSettings';

import moment from '../../node_modules/moment';

let sb;
let main;
let popin;
let overlay;

let isClose;

function handleSuccessNewsletter(e) {
	e.preventDefault();
	const form = $(e.currentTarget);
	const field = $('.form-content', form);
	const emailField = $('#email', field);
	const message = $('.message', form);
	
	$.ajax({
		url: form.attr('action'),
		method: 'post',
		data: form.serialize(),
		dataType: 'json',
		success: (data) => {
			console.log(data);
			if (data.result_code) {
				console.log('success');
				field.slideUp(() => {
					message.empty().text(ns.tx.newsletterUserAdded); 
					message.slideDown();
				});

				const dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'forms',
					eventAction: 'submitted',
					eventLabel: 'newsletter',
				});
				
				const int = setInterval(() => {
					// closePopin();
					clearInterval(int);
				}, 2000);
			} else if (data === 'exists') {
				console.log('exist');
				emailField.addClass(ns.tx.newsletterUserExists);
				message.empty().html(ns.tx.newsletterUserExists); 
				message.slideDown();
			} else {
				console.log('error');
				message.empty().html(ns.tx.newsletterGenericError); 
				message.slideDown();
			}
		},
	});

	return false;
}

// function closePopin() {
// 	const today = moment().format('DD-MM-YYYY');

// 	// Put the object into storage
// 	localStorage.setItem('NewsletterLastSeenDate', today);
// 	overlay.classList.remove('opened');
	
// 	if (popin) popin.classList.remove('open');
// 	isClose = true;
// }

// function openNewsletter(e) {
// 	if (e) e.preventDefault();
// 	const popinClose = popin.querySelector('[data-close]');
// 	popin.classList.add('open');
// 	isClose = false;

// 	if (window.matchMedia('(max-width: 1024px)').matches) {
// 		overlay.classList.add('opened');
// 	}

// 	popinClose.addEventListener('click', closePopin);
// }

// function onScroll() {
// 	const scrollTop = isNativeScrolling ? $(main).scrollTop() : sb.scrollTop;
	
// 	const mainContainer = main.querySelector('[data-main-content]'); // if no scrollbar
// 	const mainContent = isNativeScrolling ? mainContainer : main.querySelector('.scroll-content');
// 	const mainContentHeight = mainContent.getBoundingClientRect().height;

// 	if (scrollTop > (mainContentHeight * (50 / 100)) && !isClose) {
// 		openNewsletter();
// 	}
// }

export default {
	init() {
		main = document.querySelector('[data-main-scrollbar]');
		popin = document.querySelector('[data-newsletter-popin]');
		overlay = document.querySelector('.overlay');
		// const newsletterLink = document.querySelectorAll('.newsletter-link');
		
		// const retrievedObjectFromLS = localStorage.getItem('NewsletterLastSeenDate');
		// const getLastSeenDate = retrievedObjectFromLS || '00-00-0000';
		// const compareDates = retrievedObjectFromLS ? moment().format('DD-MM-YYYY') !== getLastSeenDate : true;

		// if (!isNativeScrolling && popin && (!retrievedObjectFromLS || compareDates)) {
		// 	sb = Scrollbar.get(main);

		// 	sb.addListener(onScroll);
		// 	onScroll();
		// } else if (isNativeScrolling && popin && (!retrievedObjectFromLS || compareDates)) {
		// 	document.querySelector('main').addEventListener('scroll', (e) => {
		// 		onScroll(e, true);
		// 	});
		// 	onScroll(null, true);
		// }

		// newsletterLink.forEach((curr) => {
		// 	curr.addEventListener('click', openNewsletter);
		// });
		
		const form = $('[data-form-newsletter]');
		form.on('submit', (e) => {
			handleSuccessNewsletter(e);
		});
	},
};
