import ns from './Namespace';
import CustomEvent from './EventPolyfill';
import TagManager from './TagManager';

const CART_SKYSPA_KEY = 'skyspa_cart';
export const TYPE_GIFTCERTIFICATE = 'gift-certificate';
export const TYPE_GIFTCARD = 'gift-card';

export const CART_UPDATED_EVENT = 'SKYSPA_CART_UPDATED_EVENT';
export const CART_ITEM_ADDED_EVENT = 'CART_ITEM_ADDED_EVENT';

export const DELIVERY_EMAIL = 'email';
export const DELIVERY_MAIL = 'mail';
export const DELIVERY_ONLOCATION = 'onlocation';

const elemMaker = document.createElement('div');

function guidGenerator() {
	const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
}

let cart = {};
//cart est location-specific. On peut pas acheter des items de 2 locs en meme temps, les IDs des items dependent de la location
const locId = String(ns.booker_location);

function updateCart() {

	localStorage.setItem(CART_SKYSPA_KEY, JSON.stringify(cart));
	window.dispatchEvent(new CustomEvent(CART_UPDATED_EVENT));
}

function init() {
	const savedCart = JSON.parse(localStorage.getItem(CART_SKYSPA_KEY) || '{}');

	if (ns.checkoutSuccess) {
		// console.log(savedCart[locId]);
		TagManager.payment(savedCart[locId]);
		// cart = savedCart;
		updateCart();
	} else {
		cart = savedCart;
	}
	// console.log(cart);
}


/*
item = {
	type: TYPE_GIFTCARD | TYPE_GIFTCERTIFICATE,
	delivery_type: DELIVERY_EMAIL | DELIVERY_MAIL | DELIVERY_ONLOCATION
	location: {},
	treatment: {},
	template_id: 0,
	value: 0,
	for: '',
	message: '',
}
*/
function addItem(item) {
	item.id = guidGenerator();
	// console.log(item);
	get().push(item);
	TagManager.addToCart(item);

	window.dispatchEvent(new CustomEvent(CART_ITEM_ADDED_EVENT));
	// console.log(cart);
	updateCart();
}

function removeItem(id) {
	const index = get().findIndex(x => x.id === id);
	if (index >= 0) {
		const items = get().splice(index, 1);
		TagManager.removeFromCart(items[0]);
	}

	updateCart();
}

function get() {
	cart[locId] = cart[locId] || [];
	return cart[locId];
}

function clearCart() {
	cart = cart || {};
	cart[locId] = [];
	updateCart();
}

function make(html) {
	elemMaker.innerHTML = html.trim();
	return elemMaker.firstChild;
}

export function formatPrice(price) {
	return ns.lang === 'fr' ? `${price.toFixed(2)}$` : `$${price.toFixed(2)}`;
}

export function generateItem(item) {
	// const src = item.type === TYPE_GIFTCERTIFICATE ? `/c/skyspa/img/gift-certificates/thumb/card-${item.template_id}.jpg` : '/c/skyspa/img/gift-certificates/thumb/gift-card.jpg';
	const src = '/c/skyspa/img/gift-certificates/thumb/gift-card.jpg';

	const elem = make(`
		<div class="cart-item">
			<img src="${src}" />
			<div class="infos">
				<div class="name">${item.quantity} X ${item.description}</div>
				<div class="delivery">${ns.tx.delivery.type}: ${ns.tx.delivery[item.delivery_type]}</div>
				<div class="price">${formatPrice(item.value * item.quantity)}</div>
				<div class="remove" data-remove-item="${item.id}">${ns.tx.cartRemove}</div>
			</div>
		</div>
	`);

	return elem;
}

function getSubtotal() {
	return formatPrice(get().reduce((total, item) => {
		// console.log(item);
		return total + (item.value * item.quantity);
	}, 0));
}

function prepareNetbanx() {
	return get().map((item) => {
		// let sku = TYPE_GIFTCARD;
		// if (item.type === TYPE_GIFTCERTIFICATE) {
		// 	if (item.treatment) {
		// 		sku = item.treatment.booker_id;
		// 	} else if (item.package) {
		// 		sku = item.package.booker_id;
		// 	}
		// }

		const location = item.location && item.location.booker_id;

		return {
			...item,
			// amount: parseInt(item.value * 100, 10),
			// sku,
			location,
		};
	});
}

export default {
	init,
	addItem,
	removeItem,
	get,
	clearCart,
	getSubtotal,
	prepareNetbanx,
};
