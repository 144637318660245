'format es6';
'use strict';

import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';

import { scrollToElem } from './utils/scrollTo';
import { isNativeScrolling } from './AppSettings';

let sb;

let YTplayer;
let isPlayerReady;

let videoOverlay;
let player;
let close;

const CLOSE_ANIMATION_TIME = 600;
const ACTIVE_CLASS = 'opened';

const TARGET_ELEMENT_DATA = 'video-wrapper';
const TARGET_ELEMENT_SELECTOR = `[data-${TARGET_ELEMENT_DATA}]`;
const playerBtns = $(TARGET_ELEMENT_SELECTOR);

const EVENT_NAMESPACE = 'lagrange';

function closeVideoPlayer(e) {
	// e.preventDefault();
	videoOverlay.removeClass(ACTIVE_CLASS);

	setTimeout(() => {
		YTplayer.destroy();
	}, CLOSE_ANIMATION_TIME);
}

function setupYoutube(e) {
	e.preventDefault();
	const clicked = $(e.currentTarget);
	const clickedID = clicked.data(TARGET_ELEMENT_DATA);

	// The API will call this function when the video player is ready.
	function onPlayerReady(event) {
		event.target.playVideo();
		videoOverlay.addClass(ACTIVE_CLASS);

		const dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: 'cta',
			eventAction: 'button click',
			eventLabel: 'videoplay',
		});
	}

	// The API calls this function when the player's state changes.
	function onPlayerStateChange(event) {
		if (event.data === 0) {
			closeVideoPlayer();
		}
	}

	function createPlayer() {
		const youtubePlayElem = document.querySelector('[data-player]');
		YTplayer = new YT.Player(youtubePlayElem, {
			events: {
				onReady: onPlayerReady,
				onStateChange: onPlayerStateChange,
			},
		});
	}

	const source = `https://www.youtube.com/embed/${clicked.data(TARGET_ELEMENT_DATA)}?rel=0&modestbranding=1&showinfo=0&enablejsapi=1&playsinline=1`;
	const html = `<iframe width="100%" height="100%" src="${source}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen data-player></iframe>`;

	player.empty().append(html);

	if (isPlayerReady && !player.is(':empty')) {
		if (!isNativeScrolling) {
			sb.scrollIntoView(clicked[0]);
		} else {
			scrollToElem(clicked, { container: $('main') });
		}

		createPlayer();
	}
}

window.onYouTubeIframeAPIReady = () => {
	isPlayerReady = true;
};

export default {
	init() {	
		videoOverlay = $(`
			<div class="video-overlay">
				<div class="close"><i class="icon-cancel"></i></div>
				<div class="player"></div>
			</div>
		`);
		player = videoOverlay.find('.player');
		close = videoOverlay.find('.close');
		sb = Scrollbar.get(document.querySelector('[data-main-scrollbar]'));
		
		if (playerBtns.length === 0) return;

		$('body').append(videoOverlay);

		close.on(`click.${EVENT_NAMESPACE}.video_player`, closeVideoPlayer);
		playerBtns.on(`click.${EVENT_NAMESPACE}.video_player`, setupYoutube);
	},
};
