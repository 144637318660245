'format es6';
'use strict';

import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import { TweenMax } from 'gsap';
import imagesloaded from 'imagesloaded';

import { isNativeScrolling } from './AppSettings';


let sb;
let pageContainer;
let timelineContainer;
let animatedDate;
let animatedDateInner;
let originalOffset = 0;

let boxPositions = [];
let lastYearBox = { date: '', position: 0 };
let lastBox = { date: '', position: 0 };
let dates = [];

let containerPaddingTop = 0;
let containerPaddingBottom = 0;
let containerHeight = 0;
let dateHeight = 0;

function moveDate(st, wh) {
	const offset = originalOffset - st;
	
	const top = Math.max(containerPaddingTop, -offset + (wh / 2));
	animatedDate.style.transform = `translate(0, ${top}px)`;
}

function moveDateNative(st, wh) {
	const offset = originalOffset - st;
	
	const rawTop = -offset + (wh / 2);

	const isFixed = animatedDate.classList.contains('fixed');
	if (rawTop < containerPaddingTop && isFixed) {
		animatedDate.classList.remove('fixed');
		animatedDate.classList.remove('bottom');
	} else if (rawTop >= containerPaddingTop) {
		if (rawTop + (dateHeight / 2) >= containerHeight - containerPaddingBottom) {
			animatedDate.classList.remove('fixed');
			animatedDate.classList.add('bottom');
		} else {
			animatedDate.classList.add('fixed');
			animatedDate.classList.remove('bottom');
		}
	}
}

function onScroll() {
	const st = pageContainer.scrollTop;
	const wh = window.innerHeight;
	
	if (isNativeScrolling) {
		moveDateNative(st, wh);
	} else {
		moveDate(st, wh);
	}

	const currentBox = boxPositions.find(box => st + (wh / 2) <= box.position);
	
	if (currentBox && currentBox !== lastBox) {
		boxPositions.forEach(box => box.elem.classList.remove('active'));
		currentBox.elem.classList.add('active');
		lastBox = currentBox;
	}
	
	if (currentBox && currentBox.date !== lastYearBox.date) {
		let index = dates.findIndex(d => d === currentBox.date);
		if (index < 0) index = 0;
		
		TweenMax.to(animatedDateInner, 0.3, { y: -(index) * animatedDate.clientHeight });

		lastYearBox = currentBox;
	}
}

function onResize() {
	boxPositions = Array.from(document.querySelectorAll('[data-date]')).map((elem) => {
		const rect = elem.getBoundingClientRect();
		return {
			elem,
			date: elem.getAttribute('data-date'),
			position: rect.top + pageContainer.scrollTop + elem.clientHeight,
		};
	});
	containerPaddingTop = parseInt($(timelineContainer).css('padding-top'), 10);
	containerPaddingBottom = parseInt($(timelineContainer).css('padding-bottom'), 10);
	containerHeight = timelineContainer.clientHeight;
	dateHeight = animatedDate.clientHeight;
	originalOffset = timelineContainer.getBoundingClientRect().top + pageContainer.scrollTop;
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		pageContainer = document.querySelector('main');

		animatedDate = document.querySelector('[data-timeline-date]');
		if (!animatedDate) return;

		animatedDateInner = animatedDate.querySelector('[data-timeline-date-inner]');
		timelineContainer = document.querySelector('[data-timeline-container]');

		dates = Array.from(document.querySelectorAll('[data-date]')).reduce((c, elem) => {
			const date = elem.getAttribute('data-date');
			if (!c.find(d => d === date)) c.push(date);
			return c;
		}, []);
		
		dates.forEach(date => {
			animatedDateInner.innerHTML += `<div data-year="${date}">${date}</div>`;
		});

		window.addEventListener('resize', onResize);
		onResize();
		
		if (isNativeScrolling) {
			pageContainer.addEventListener('scroll', (e) => {
				onScroll();
			});
			animatedDate.classList.add('native');
		} else {
			sb = Scrollbar.get(main);
			pageContainer = sb;

			sb.addListener(onScroll);
		}

		onScroll();

		imagesloaded(document.querySelector('body'), {}, onResize);
	},
};
