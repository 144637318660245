let popin;
let seen;
let closeBtn;

const LS_KEY = 'seen_alert';

function onClickClose() {
	popin.classList.remove('open');
	localStorage.setItem(LS_KEY, Date.now());
}

const DELAY = 3 * 60 * 60 * 1000; // 3 hours

function init() {
	popin = document.querySelector('[data-alert-popin]');

	if (popin) {
		seen = localStorage.getItem(LS_KEY);

		if (!seen || seen === 'true' || parseInt(seen, 10) + DELAY <= Date.now()) {
			popin.classList.add('open');
		}

		closeBtn = popin.querySelector('[data-close]');
		closeBtn.addEventListener('click', onClickClose);
	}
}

export default {
	init,
};
