'format es6';
'use strict';

import $ from 'jquery';
import { isElementVisible } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';

import Scrollbar from 'smooth-scrollbar';

let sb;
let videos;

function onScroll(e, native = false) {
	videos.forEach((vid) => {
		const isVisible = native ? isElementVisible(vid) : sb.isVisible(vid);

		if (isVisible) {
			vid.play();
		} else if (!isVisible) {
			vid.pause();
		}
	});
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		videos = Array.from(main.querySelectorAll('[data-video-loop]'));

		if (!isNativeScrolling) {
			sb = Scrollbar.get(main);

			sb.addListener(onScroll);
			setTimeout(() => {
				onScroll();
			}, 300);
		} else if (isNativeScrolling) {
			document.querySelector('main').addEventListener('scroll', (e) => {
				onScroll(e, true);
			});
			onScroll(null, true);
		}
	},
};
