'format es6';
'use strict';

import $ from 'jquery';
import selectric from 'selectric';

function setLocation(e) {
	e.preventDefault();
	window.location.href = $(e.currentTarget).val();
}

export default {
	init() {
		$('[data-select-branches]').off('change').on('change', setLocation);
		selectric();

		const selects = $('[data-selectric-branches]');
		selects.selectric({
			nativeOnMobile: true,
			disableOnMobile: false,
			arrowButtonMarkup: '<i class="arrow-btn icon-down-open-mini"></i>',
			optionsItemBuilder: '<span>{text}</span>',
		});
	},
};
