'format es6';
'use strict';

import $ from 'jquery';
import imagesLoaded from '../../node_modules/imagesloaded';

function calcHeight() {
	const additionnalHeight = 180;
	const additionnalHeightMD = 130;
	const halfhalfBlock = $('[data-get-height]');

	$(window).on('resize.skyspa.halfhalf', () => {
		halfhalfBlock.each((elem) => {
			let h = 0;

			const bg = $('[data-bg]', halfhalfBlock[elem]);
			const text = $('[data-text]', halfhalfBlock[elem]);
			const image = $('[data-image]', halfhalfBlock[elem]);

			if (window.matchMedia('(min-width: 1500px)').matches) { /*desktop*/
				h = text.outerHeight(true) + additionnalHeight;
			} else if (window.matchMedia('(max-width: 1499.98px)').matches) {
				h = text.outerHeight(true) + additionnalHeightMD;
			}
			
			if (window.matchMedia('(max-width: 1024px)').matches) {
				bg.css('height', '');
				$(halfhalfBlock[elem]).css('min-height', '');
			} else {
				bg.css('height', h);
				$(halfhalfBlock[elem]).css('min-height', text.outerHeight(true));
			}
		});
	}).resize();
}

export default {
	init() {
		const loadedImages = new Promise((resolve) => {
			imagesLoaded(document.body, { background: true }, resolve);
		});
		
		loadedImages.then(() => {
			calcHeight();
		});
	},
};
