import gsap, { TweenMax } from 'gsap';
import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import { isNativeScrolling } from '../AppSettings';

const $window = $(window);
const defaults = {
	offset: 0,
	time: 1.2,
	container: $window,
	onUpdate() { },
	onComplete() { },
};

/**
 * Scrolls to position using TweenMax. If an offset is 
 * defined, scrolls that amount higher than the target element.
 *
 * @param {Number} pos
 * @param {Object} options
 */
export const scrollTo = (pos, options = {}) => {
	const opt = Object.assign({}, defaults, options);

	if (isNativeScrolling) {
		const scroll = {
			y: opt.container.scrollTop(),
		};
	
		TweenMax.to(scroll, opt.time, {
			y: pos + opt.offset,
			ease: gsap.Cubic.easeInOut,
			onUpdate: () => {
				opt.container.scrollTop(scroll.y);
				opt.onUpdate(scroll.y);
			},
			onComplete: opt.onComplete,
		});
	} else {
		const sb = Scrollbar.get(document.querySelector('[data-main-scrollbar]'));
		sb.scrollTo(0, pos, opt.time * 1000);
	}
};

/**
 * Scrolls to an element.
 *
 * @param {JQuery} el
 * @param {Object} options
 */
export const scrollToElem = (el, options = {}) => {
	const opt = Object.assign({}, defaults, options);
	const $el = $(el);

	if (isNativeScrolling) {
		const scrollValue = $el[0].offsetTop;
		console.log($el[0]);
		scrollTo(scrollValue, opt);
	} else {
		const sb = Scrollbar.get(document.querySelector('[data-main-scrollbar]'));
		sb.scrollIntoView($el[0], { offsetTop: options.offset });
	}
};
