'format es6';
'use strict';

/**
 * @function isEdge
 * 
 * @param {Number[]} versions Array of version numbers 
 * 
 * @return {Boolean}
 */
export function isEdge(versions) {
	return versions.reduce((c, version) => {
		return c + (~window.navigator.userAgent.indexOf(`Edge/${version}`) ? 1 : 0);
	}, 0) !== 0;
}

export const isAllEdge = window.navigator.userAgent.indexOf('Edge') > -1;

export const isIE11 = Boolean(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const isMac = navigator.platform.toLowerCase().indexOf('mac') > -1;

function iOSversion() {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		// supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
		const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
		return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	}
	return [null];
}

export const iosVersion = iOSversion()[0];

function detectmob() { 
	if ( navigator.userAgent.match(/Android/i)
	|| navigator.userAgent.match(/webOS/i)
	|| navigator.userAgent.match(/iPhone/i)
	|| navigator.userAgent.match(/iPad/i)
	|| navigator.userAgent.match(/iPod/i)
	|| navigator.userAgent.match(/BlackBerry/i)
	|| navigator.userAgent.match(/Windows Phone/i)
	) {
		return true;
	}
	return false;
}

export const isMobileDevice = detectmob();
