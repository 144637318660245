'format es6';
import { slideToggle } from './utils/slide';

function handleCollapser(e) {
	e.preventDefault();
	const clicked = e.currentTarget;
	const rootId = clicked.getAttribute('data-expendable');
	const control = document.querySelector(`[data-expendable-control="${rootId}"]`);
	const target = document.querySelector(`[data-expendable-target="${rootId}"]`);

	control.classList.toggle('open');
	slideToggle(target);
}

export default {
	init() {
		const collapser = Array.from(document.querySelectorAll('[data-expendable]'));
		if (collapser.length <= 0) return;
		collapser.forEach((curr) => {
			curr.addEventListener('click', handleCollapser);
		});
	},
};
