import BookingManager, { BOOKING_MANAGER_UPDATE, PACKAGE_PREFIX } from './BookingManager';
import ns from './Namespace';
import Cart, {
	TYPE_GIFTCERTIFICATE,
	TYPE_GIFTCARD,
	DELIVERY_EMAIL,
	DELIVERY_MAIL,
	DELIVERY_ONLOCATION,
} from './Cart';

const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const dom = {};

function init() {
	dom.form = document.querySelector('[data-gift-card-form]');
	if (!dom.form) return;

	BookingManager.init();
	BookingManager.setLocation(ns.location_id.toString(), false);

	setupDomReferences();

	onUpdateManager(true);

	dom.form.addEventListener('input', onCheckCanAddToCart);

	dom.treatment.addEventListener('change', onChangeTreatment);
	dom.card_value.addEventListener('input', updatePrice);
	dom.qty.addEventListener('input', getQty);
	dom.qty.addEventListener('input', updatePrice);

	dom.durations.forEach(x => x.addEventListener('change', onChangeDuration));
	dom.thermic_exp.forEach(x => x.addEventListener('change', onChangeThermicExp));
	dom.card_types.forEach(x => x.addEventListener('change', onChangeCardType));
	dom.delivery_types.forEach(x => x.addEventListener('change', onChangeDeliveryType));

	dom.add_cart.addEventListener('click', onClickAddToCart);
	window.addEventListener(BOOKING_MANAGER_UPDATE, onUpdateManager);
	onChangeDuration();
	onChangeDeliveryType();
	onChangeThermicExp();
	onChangeCardType();
	onChangeTreatment();

}

function setupDomReferences() {
	dom.add_cart = dom.form.querySelector('[data-add-cart]');
	dom.treatment = dom.form.querySelector('[data-gift-card-treatment]');
	dom.template_row = dom.form.querySelector('[data-template-row]');
	dom.templates = Array.from(dom.form.querySelectorAll('input[name=templates]'));
	dom.durations = Array.from(dom.form.querySelectorAll('input[name=duration]'));
	dom.durations_wrapper = dom.form.querySelector('[data-duration-wrapper]');
	dom.thermic_exp = Array.from(dom.form.querySelectorAll('input[name=thermic_experience]'));
	dom.thermic_wrapper = dom.form.querySelector('[data-thermic-wrapper]');
	dom.card_types = Array.from(dom.form.querySelectorAll('input[name=card_type]'));
	dom.delivery_types = Array.from(dom.form.querySelectorAll('input[name=delivery_type]'));

	dom.recipient = dom.form.querySelector('[data-gift-card-email]');
	//notice of mail delivery details
	dom.shipping_precisions = dom.form.querySelector('[data-shipping-precisions]');
	dom.pickup_precisions = dom.form.querySelector('[data-pickup-precisions]');
	dom.email_precisions = dom.form.querySelector('[data-email-precisions]');
	

	dom.qty = dom.form.querySelector('input[name=qty]');
	dom.qty_row = dom.form.querySelector('[data-gift-card-qty]');

	dom.price = dom.form.querySelector('[data-price]');
	dom.card_value = dom.form.querySelector('[data-gift-card-value]');
	dom.to = dom.form.querySelector('input[name=to]');
	dom.to_email = dom.form.querySelector('input[name=to_email]');
	dom.from = dom.form.querySelector('input[name=from]');
	dom.message = dom.form.querySelector('textarea[name=message]');

	dom.choose_card = dom.form.querySelector('[data-choose-card]');
	dom.choose_certificate = dom.form.querySelector('[data-choose-certificate]');

}

function getCardType() {
	return dom.card_types.find(x => x.checked).value;
}
function getDeliveryType() {
	return dom.delivery_types.find(x => x.checked).value;
}


function updateTreatments(param) {
	// console.log(

	let preselectedPackage = null;
	let preselectedTreatment = null;
	if (param === true) {
		const preselected = String(dom.treatment.dataset.preselect);
		const m = preselected.match(/(p|t)([0-9]+)/);
		if (m) {
			if (m[1] === 'p') preselectedPackage = m[2]; // eslint-disable-line
			if (m[1] === 't') preselectedTreatment = m[2]; // eslint-disable-line
		}
		// console.log(preselectedPackage);
	}

	const currentTreatment = BookingManager.getTreatment();
	dom.treatment.innerHTML = '';
	BookingManager.getTreatments(false).sort((a, b) => a.order - b.order).forEach((treatment) => {
		const selected = ((currentTreatment && currentTreatment.id === treatment.id) || (preselectedTreatment && preselectedTreatment === treatment.id)) ? 'selected' : '';
		// console.log(treatment.id, selected);
		dom.treatment.innerHTML += `<option value="${treatment.id}" ${selected}>${treatment.name_lng}</option>`;
	});

	//also adds packages
	const currentPackage = BookingManager.getPackage();
	const packs = BookingManager.getPackages(false);

	packs.forEach((pack) => {
		const selected = ((currentPackage && currentPackage.id === pack.id) || (preselectedPackage && preselectedPackage === pack.id)) ? 'selected' : '';

		dom.treatment.innerHTML += `<option value="${PACKAGE_PREFIX}${pack.id}" ${selected}>${pack.name}</option>`;
	});

	//param passed for first call is a boolean indicating init
	if (param === true) {
		BookingManager.setTreatment(dom.treatment.value);
		// console.log(dom.treatment.value);
	}

}

function updateOptions() {
	//if package, hide therm exp. & duration
	const currentTreatment = BookingManager.getTreatment();
	const pack = BookingManager.getPackage();


	// console.log(pack);

	//2019-11-04 (squat novembre) forfait DIVA a déjà experience thermique. Hardcoded parce qu'on va updated le fonctionnement de la réservation anyway
	const isDiva = currentTreatment && currentTreatment.code === 'divas-package';

	const thermicDisplayOptions = pack || isDiva ? 'none' : '';
	if (isDiva) BookingManager.setThermicExp(true);
	const durationDisplayOptions = pack ? 'none' : '';
	dom.durations_wrapper.style.display = durationDisplayOptions;
	dom.thermic_wrapper.style.display = thermicDisplayOptions;
}

function updateDurations() {
	const selectedDuration = BookingManager.getDuration();
	const durations = BookingManager.getDurations();

	dom.durations.forEach(duration => {
		if (durations.find(d => d === duration.value)) {
			if (duration.value === selectedDuration) {
				duration.checked = true;
			}
			duration.disabled = false;
		} else {
			if (duration.checked) {
				duration.checked = false;
			}
			duration.disabled = true;
		}
	});
}

function updatePrice() {
	let price = getCardType() === TYPE_GIFTCERTIFICATE ? BookingManager.getPrice(true) : parseInt(dom.card_value.value, 10).toFixed(2);
	if (price < 0 || Number.isNaN(Number(price))) price = 0;

	const qty = getQty();
	price *= qty;
	if (ns.lang === 'fr') {
		price = `${price}$`;
	} else {
		price = `$${price}`;
	}
	dom.price.innerHTML = price;
}

function getQty() {
	let qty = Math.floor(Number(dom.qty.value));

	//always 1 for delivery by email
	if (getDeliveryType() === DELIVERY_EMAIL) {
		qty = 1;
	}
	dom.qty.value = qty || 1;
	// console.log(qty);
	return qty;
}

function onChangeTreatment() {
	BookingManager.setTreatment(dom.treatment.value);
}

function onChangeDuration() {
	const d = dom.durations.find(x => x.checked);
	if (d) BookingManager.setDuration(d.value);
}

function onChangeThermicExp() {
	BookingManager.setThermicExp(dom.thermic_exp.find(x => x.checked).value === '1');
}

function onChangeCardType() {
	if (getCardType() === TYPE_GIFTCERTIFICATE) {
		dom.choose_certificate.style.display = 'block';
		dom.choose_card.style.display = 'none';
	} else {
		dom.choose_certificate.style.display = 'none';
		dom.choose_card.style.display = 'block';
	}

	updatePrice();
}

function onChangeDeliveryType() {
	const deliveryType = getDeliveryType();

	if (deliveryType === DELIVERY_EMAIL) {
		dom.template_row.style.display = 'block';
		dom.recipient.style.display = 'block';
		dom.shipping_precisions.style.display = 'none';
		dom.pickup_precisions.style.display = 'none';
		dom.email_precisions.style.display = 'block';

		//by email: only one at a time (multiple quantities don't exist in Booker for these)
		dom.qty.value = 1;
		dom.qty_row.style.display = 'none';

	} else if (deliveryType === DELIVERY_MAIL) {
		dom.template_row.style.display = 'none';
		dom.recipient.style.display = 'none';
		dom.shipping_precisions.style.display = 'block';
		dom.pickup_precisions.style.display = 'none';
		dom.email_precisions.style.display = 'none';
		
		dom.qty_row.style.display = 'block';

	} else {
		dom.shipping_precisions.style.display = 'none';
		dom.pickup_precisions.style.display = 'block';
		dom.template_row.style.display = 'none';
		dom.recipient.style.display = 'none';
		dom.email_precisions.style.display = 'none';
		dom.qty_row.style.display = 'block';
	}
}

function onUpdateManager(p) {
	updateTreatments(p);
	updateDurations();
	updateOptions();
	updatePrice();
}

function onCheckCanAddToCart() {
	const deliveryType = getDeliveryType();
	const cardType = getCardType();

	let isValid = true;

	//for certificates, we need to have name, email, etc.
	if (deliveryType === DELIVERY_EMAIL) {
		if (dom.from.value === '' || dom.to.value === '' || !emailRegexp.test(dom.to_email.value) || dom.message.value === '') {
			isValid = false;
		}
	}

	const q = getQty();
	if (!q) isValid = false;

	//for amounts, make sure it is not 0
	if (cardType === TYPE_GIFTCARD && parseInt(dom.card_value.value, 10) > 0) {
		isValid &= true;
	}

	const fcn = isValid ? 'remove' : 'add';
	dom.add_cart.classList[fcn]('disabled');
}

function onClickAddToCart() {
	if (!dom.add_cart.classList.contains('disabled')) {
		const type = getCardType();
		const deliveryType = getDeliveryType();
		
		let treatment = null;
		let pckg = null;

		let price;
		let description;

		if (type === TYPE_GIFTCERTIFICATE) {
			treatment = BookingManager.computeService(true);
			pckg = BookingManager.getPackage();
			price = Number(BookingManager.getPrice(true));
			// console.log(price);
			if (treatment) {
				description = treatment.name_lng;
			} else if (pckg) {
				description = pckg.name;
			}
		} else {
			price = parseInt(dom.card_value.value.replace('$', ''), 10);
			description = `${ns.tx.cartGiftCard} ${price}$`;
		}

		const item = {
			type,
			delivery_type: deliveryType,
			location: BookingManager.getLocation(),
			treatment,
			package: pckg,
			description,
			value: price,
			quantity: getQty(),
		};

		if (deliveryType === DELIVERY_EMAIL) {
			item.template_id = dom.templates.find(x => x.checked).value;
			item.to = dom.to.value;
			item.from = dom.from.value;
			item.message = dom.message.value;
			item.to_email = dom.to_email.value;
		}

		Cart.addItem(item);
	}
}

export default {
	init,
};
