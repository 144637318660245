import $ from 'jquery';

function Wrap(i, node) {
	const rootNode = $(node);
	
	const iframe = $('iframe', rootNode);
	iframe.wrap("<div class='video-container'></div>");
}

export default {
	init() {
		$('[data-article-content]').each(Wrap);
	},
};
