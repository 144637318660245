'format es6';
'use strict';

import $ from 'jquery';
import { docReady } from './utils/docReady';
import { isElementVisible } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';

import Scrollbar from 'smooth-scrollbar';
let sb;
let lastDir = 0;
let previousScroll = 0;
let isMobileOpen = false;

let isMobile;

function onScroll(e) {

	// pour la landing page de campagne, le behavior du menu doit être
	// by passé! :S
	const isCampaign = document.querySelector('[data-menu]');

	if (isCampaign.getAttribute('data-menu') === 'campaign') return;

	const scrollTop = isNativeScrolling ? document.querySelector('main').scrollTop : sb.scrollTop;
	const menu = document.querySelector('[data-menu]');

	let dir = scrollTop - previousScroll;
	dir = Math.abs(dir) / dir;

	if (scrollTop >= 50) {
		menu.classList.add('sticky');

		if (lastDir !== dir && !isMobileOpen) {
			if (dir === 1) {
				menu.classList.add('menu-hidden');
			} else if (dir === -1) {
				menu.classList.remove('menu-hidden');
			}
		}

		lastDir = dir;

	} else {
		menu.classList.remove('sticky');
	}

	previousScroll = scrollTop;
}

function openMobileMenu(e) {
	e.preventDefault();

	const menuBtn = e.currentTarget;
	const menu = document.querySelector('[data-menu]');

	menuBtn.classList.toggle('opened');
	menu.classList.toggle('opened');

	isMobileOpen = !isMobileOpen;
}

function clickHandler(e) {
	const clicked = e.currentTarget;
	const children = $(clicked).siblings('ul');

	clicked.classList.toggle('opened');
	children.toggleClass('opened');
	children.slideToggle();
}

function resizeHandler() {
	const menuParents = document.querySelectorAll('[data-has-children]');
	const menu = document.querySelector('[data-menu]');

	if (window.matchMedia('(max-width: 1024px)').matches && !isMobile) {
		isMobile = true;

		Array.from(menuParents).forEach((curr) => {
			curr.addEventListener('click', clickHandler);
		});
	} else if (window.matchMedia('(min-width: 1024px)').matches && isMobile) {
		isMobile = false;

		// remove les event des elements avec des children
		Array.from(menuParents).forEach((curr) => {
			curr.removeEventListener('click', clickHandler);
		});

		// remove tout les class opened et clear les syles inline
		const openedElement = menu.querySelectorAll('.opened');
		const styledElement = menu.querySelectorAll('[style]');

		menu.classList.remove('opened');
		Array.from(openedElement).forEach((curr) => {
			curr.classList.remove('opened');
		});
		Array.from(styledElement).forEach((curr) => {
			curr.removeAttribute('style');
		});
	}
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		const mobileBtn = document.querySelector('[data-mobile-menu]');

		mobileBtn.addEventListener('click', openMobileMenu);
		window.addEventListener('resize', resizeHandler);
		resizeHandler();

		if (!isNativeScrolling) {
			sb = Scrollbar.get(main);

			sb.addListener(onScroll);
			onScroll();
		} else {
			document.querySelector('main').addEventListener('scroll', (e) => {
				onScroll(e, true);
			});
			onScroll(null, true);
		}
	},
};

