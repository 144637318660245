import Cart, { CART_UPDATED_EVENT } from './Cart';
import CartSidebar from './CartSidebar';

const dom = {};

function init() {
	setupDomReferences();
	onCartUpdated();

	dom.elem.forEach(element => {
		element.addEventListener('click', CartSidebar.toggleCart);
	});

	window.addEventListener(CART_UPDATED_EVENT, onCartUpdated);
}

function setupDomReferences() {
	dom.elem = document.querySelectorAll('[data-cart-icon]');
}

function onCartUpdated() {
	const qty = Cart.get().length;
	
	dom.elem.forEach(element => {
		dom.count = element.querySelector('[data-cart-icon-count]');
		dom.count.innerHTML = qty;
		dom.count.classList[qty ? 'add' : 'remove']('visible');
	});
}

export default {
	init,
};
