import $ from 'jquery';
import garlic from 'garlicjs';
import Cart, { 
	CART_UPDATED_EVENT,
	DELIVERY_EMAIL,
	DELIVERY_MAIL,
	DELIVERY_ONLOCATION,
	generateItem,
	formatPrice,
} from './Cart';
import { STATE_ALLOWED_COUNTRIES } from './BookingManager';
import { scrollToElem } from './utils/scrollTo';
import ns from './Namespace';

import { 
	getEndpointUrl, 
	GET_TOTAL,
	PAYMENT,
} from './Api';
import TagManager from './TagManager';

const dom = {};

function init() {
	dom.ctn = document.querySelector('[data-checkout-cart]');
	if (!dom.ctn) return;

	TagManager.checkout(Cart.get());

	setupDomReferences();

	if (ns.checkoutSuccess) {
		dom.form.querySelectorAll('input, select').forEach(
			(elem) => {
				$(elem).garlic('destroy');
			}
		);
		dom.form.reset();
	}

	addListeners();

	onClickToggleShipping();

	onCartUpdated();
}

function setupDomReferences() {
	dom.form = document.querySelector('[data-checkout-form]');

	dom.infos = {};

	dom.infos.email = dom.form.querySelector('#email');

	dom.shipping_address_toggle = document.querySelector('[data-shipping-address-toggle]');
	dom.shipping_address_ctn = document.querySelector('[data-shipping-address-ctn]');

	dom.checkout_subtotal = document.querySelector('[data-checkout-subtotal]');
	dom.checkout_shipping = document.querySelector('[data-checkout-shipping]');
	dom.checkout_discount = document.querySelector('[data-checkout-discount]');
	dom.discount_code = document.querySelector('[data-checkout-discount-code]');
	dom.checkout_discount_row = document.querySelector('[data-discount-row]');
	dom.checkout_tps = document.querySelector('[data-checkout-tps]');
	dom.checkout_tvq = document.querySelector('[data-checkout-tvq]');
	dom.checkout_total = document.querySelector('[data-checkout-total]');
	dom.coupon = document.querySelector('[data-checkout-coupon]');
	dom.apply_coupon = document.querySelector('[data-checkout-apply-coupon]');
	dom.coupon_msg = document.querySelector('[data-coupon-message]');
	dom.taxes_note = document.querySelector('[data-taxes-note]');
	
	dom.confirm_checkout = document.querySelector('[data-confirm-checkout]');
	//all elements that are relevent for orders that need shipping
	dom.with_shipping = Array.from(dom.form.querySelectorAll('[data-withshipping]'));

	dom.shipping = {
		countrySelect: document.querySelector('[data-shipping-country-select]'),
		stateSelect: document.querySelector('[data-shipping-state-select]'),
		stateRow: document.querySelector('[data-shipping-state-row]'),
		address_1: document.querySelector('#shipping_address_1'),
		address_2: document.querySelector('#shipping_address_2'),
		city: document.querySelector('#shipping_city'),
		postal_code: document.querySelector('#shipping_postal_code'),
		phone: document.querySelector('#shipping_phone'),
		name: document.querySelector('#shipping_full_name'),
	};
	
	dom.billing = {
		name: document.querySelector('#billing_full_name'),
		countrySelect: document.querySelector('[data-billing-country-select]'),
		stateSelect: document.querySelector('[data-billing-state-select]'),
		stateRow: document.querySelector('[data-billing-state-row]'),
		address_1: document.querySelector('#billing_address_1'),
		address_2: document.querySelector('#billing_address_2'),
		city: document.querySelector('#billing_city'),
		postal_code: document.querySelector('#billing_postal_code'),
		phone: document.querySelector('#billing_phone'),
	};

	window.dom = dom;
}

function addListeners() {
	dom.shipping_address_toggle.addEventListener('change', onClickToggleShipping);

	dom.billing.countrySelect.addEventListener('change', onUpdateBillingCountry);
	dom.shipping.countrySelect.addEventListener('change', onUpdateShippingCountry);
	
	dom.confirm_checkout.addEventListener('click', onConfirmCheckout);
	dom.apply_coupon.addEventListener('click', updateTotals);

	window.addEventListener(CART_UPDATED_EVENT, onCartUpdated);
}

function updateTotals(e) {
	if (e) e.preventDefault();
	const cart = Cart.get();
	if (cart.length === 0) {
		dom.checkout_shipping.innerHTML = formatPrice(0);
		dom.checkout_subtotal.innerHTML = formatPrice(0);
		dom.checkout_discount_row.style.display = 'none';
		dom.coupon_msg.innerHTML = '';
		dom.discount_code.innerHTML = '';
		dom.checkout_tps.innerHTML = formatPrice(0);
		dom.checkout_tvq.innerHTML = formatPrice(0);
		dom.checkout_total.innerHTML = formatPrice(0);
		dom.taxes_note.style.display = 'none';
		return;
	}

	const jsonCart = JSON.stringify(cart);
	$.ajax({
		url: getEndpointUrl(GET_TOTAL),
		data: {
			cart: jsonCart,
			coupon: dom.coupon.value,
			lang: ns.lang,
		},
		dataType: 'json',
		method: 'post',
		success(data) {
			// console.log(data);
			//make sure that cart is still in the same state when response received
			const currentCart = JSON.stringify(Cart.get());

			localStorage.setItem('transaction_info', JSON.stringify({
				coupon: data.coupon_code,
				total: data.discounted_total,
				shipping: data.discounted_shipping,
				tax: data.tps + data.tvq,
			}));

			if (currentCart === jsonCart) {
				
				dom.checkout_shipping.innerHTML = formatPrice(data.shipping);
				dom.checkout_subtotal.innerHTML = formatPrice(data.subtotal);
				
				if (Number(data.discount) > 0) {
					dom.checkout_discount.innerHTML = formatPrice(data.discount * -1);
					dom.checkout_discount_row.style.display = '';//show
				} else {
					dom.checkout_discount.innerHTML = formatPrice(0);
					dom.checkout_discount_row.style.display = 'none';
				}
				dom.coupon_msg.innerHTML = data.coupon_status_message;
				dom.discount_code.innerHTML = data.coupon_code;

				dom.checkout_tps.innerHTML = formatPrice(data.tps);
				dom.checkout_tvq.innerHTML = formatPrice(data.tvq);
				dom.checkout_total.innerHTML = formatPrice(data.discounted_total);
				if (data.tps || data.tvq) {
					dom.taxes_note.style.display = 'block';
				}
			}
		},
		error: (er) => {
			console.error(er);
			console.error(er.responseText);
		},
	});

}

function updateStates(type, country) {
	// console.log(type);
	if (STATE_ALLOWED_COUNTRIES.indexOf(country) > -1) {
		type.stateRow.style.display = 'block';
		type.stateSelect.setAttribute('required', 'required');

		//loop dans options, montre celles du pays selected
		// console.log(type.stateSelect.options);
		const options = Array.from(type.stateSelect.children);
		options.forEach(o => {
			
			const isShow = Number(o.getAttribute('data-country-id')) === country;
			const isSelected = o.hasAttribute('default-selected') && isShow;
			// console.log(isSelected);
			o.style.display = isShow ? '' : 'none';
			if (isSelected) {
				o.setAttribute('selected', true);
			} else {
				o.removeAttribute('selected');
			}
			if (isShow) {
				o.removeAttribute('disabled');
			} else {
				o.setAttribute('disabled', true);
			}
			// console.log(o.getAttribute('disabled'));
		});
	

	} else {
		type.stateRow.style.display = 'none';
		type.stateSelect.removeAttribute('required');
		type.stateSelect.value = null;
	}
}

function onClickToggleShipping() {
	dom.shipping_address_ctn.style.display = dom.shipping_address_toggle.checked ? 'block' : 'none';

	Object.keys(dom.shipping).forEach(k => {
		if (!dom.shipping_address_toggle.checked) {
			dom.shipping[k].removeAttribute('required');
		} else if (k !== 'address_2') {
			dom.shipping[k].setAttribute('required', true);
		}
	});

	if (dom.shipping_address_toggle.checked && STATE_ALLOWED_COUNTRIES.indexOf(dom.shipping.countrySelect.value) === -1) {
		dom.shipping.stateSelect.removeAttribute('required');
	}
}


function onCartUpdated() {
	dom.ctn.innerHTML = '';
	const list = Cart.get();
	
	list.forEach(item => {
		const elem = generateItem(item);
		elem.querySelector('[data-remove-item]').addEventListener('click', onClickRemoveItem);
		dom.ctn.appendChild(elem);
	});

	if (list.length === 0) {
		dom.ctn.innerHTML = `<div class="checkout-cart-empty">${ns.tx.cartEmpty}</div>`;
	}

	const shippingDisplay = needsShipping() ? 'block' : 'none';
	dom.with_shipping.forEach(domEl => {
		domEl.style.display = shippingDisplay;
	});
	updateTotals();
}

function needsShipping() {
	//only delivery by mail items can have shipping
	const list = Cart.get();
	return list.reduce((res, item) => {
		return res || item.delivery_type === DELIVERY_MAIL;
	}, false);
	// console.log(hasShipping);
}

function adaptBillingNetbanx() {
	return {
		name: dom.billing.name.value,
		city: dom.billing.city.value,
		country: dom.billing.countrySelect.querySelector(':checked').getAttribute('data-abbr'),
		street: dom.billing.address_1.value,
		street2: dom.billing.address_2.value,
		zip: dom.billing.postal_code.value,
		state: dom.billing.stateSelect.value,
		phone: dom.billing.phone.value,
		useAsShippingAddress: !dom.shipping_address_toggle.checked,
	};
}

function adaptShippingNetbanx() {
	return {
		name: dom.shipping.name.value,
		city: dom.shipping.city.value,
		country: dom.shipping.countrySelect.querySelector(':checked').getAttribute('data-abbr'),
		street: dom.shipping.address_1.value,
		street2: dom.shipping.address_2.value,
		zip: dom.shipping.postal_code.value,
		state: dom.shipping.stateSelect.value,
		phone: dom.shipping.phone.value,
	};
}


function validateEmail(email) {
	const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(String(email).toLowerCase());
}

function validatePhone(p) {
	const regex = /^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/i;
	return regex.test(String(p));
}

function validateZip(z) {
	const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/i;
	return regex.test(String(z));
}

function validatePostalCode(z) {
	const regex = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i;
	return regex.test(String(z));
}

function validateForm() {
	const validation = {
		isValid: true,
		errors: [],
	};

	Array.from(dom.form.querySelectorAll('.error')).forEach(err => err.classList.remove('error'));
	Array.from(dom.form.querySelectorAll('.error-msg')).forEach(err => err.remove());

	Array.concat(
		Array.from(dom.form.querySelectorAll('input[required]')),
		Array.from(dom.form.querySelectorAll('select[required]'))
	).forEach(input => {
		if (input.value === '' || input.value === undefined || input.value === null) {
			validation.isValid = false;
			validation.errors.push({
				msg: ns.lang === 'fr' ? 'Ce champ est requis.' : 'This field is required',
				input,
			});
		}
	});


	const isBillingZipValid = validateZip(dom.billing.postal_code.value) || validatePostalCode(dom.billing.postal_code.value);
	if (!isBillingZipValid) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.billing.postal_code,
			msg: ns.lang === 'fr' ? 'Format de code postal incorrect: A1A 1A1 (CAN) ou 12345 (US).' : 'Incorreclty formatted postal code: A1A1A1 (CAN) or 12345 (US)',
		});
	}
	const isShippingZipValid = !dom.shipping_address_toggle.checked || validateZip(dom.shipping.postal_code.value) || validatePostalCode(dom.shipping.postal_code.value);
	if (!isShippingZipValid) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.shipping.postal_code,
			msg: ns.lang === 'fr' ? 'Format de code postal incorrect: A1A 1A1 (CAN) ou 12345 (US).' : 'Incorreclty formatted postal code: A1A1A1 (CAN) or 12345 (US)',
		});
	}

	if (!validatePhone(dom.billing.phone.value)) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.billing.phone,
			msg: ns.lang === 'fr' ? 'Format de numéro de téléphone incorrect: 111-111-1111.' : 'Incorreclty formatted phone number: 111-111-1111',
		});
	}

	if (dom.shipping_address_toggle.checked && !validatePhone(dom.shipping.phone.value)) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.shipping.phone,
			msg: ns.lang === 'fr' ? 'Format de numéro de téléphone incorrect: 111-111-1111.' : 'Incorreclty formatted phone number: 111-111-1111',
		});
	}

	return validation;
}

function onConfirmCheckout() {
	const validation = validateForm();

	// console.log(validation);

	if (validation.isValid) {
		const shoppingCart = Cart.prepareNetbanx();
		const billingDetails = adaptBillingNetbanx();

		const data = {
			email: dom.infos.email.value,
			booker_location_id: ns.booker_location,
			profile: {
				merchantCustomerId: dom.infos.email.value,
			},
			domain_url: window.location.host,
			checkout_url: window.location.href.split('?')[0],
			locale: ns.lang,
			shoppingCart,
			coupon: dom.discount_code.innerHTML,
			billingDetails,
		};

		if (!billingDetails.useAsShippingAddress) {
			data.shippingDetails = adaptShippingNetbanx();
		}

		TagManager.checkoutProgress(Cart.get(), dom.discount_code.innerHTML);

		$.ajax({
			url: getEndpointUrl(PAYMENT),
			data,
			method: 'post',
			success: (resp) => {
				try {
					const link = JSON.parse(resp).link.find(lnk => lnk.rel === 'hosted_payment');
					window.location.href = link.uri;
				} catch (e) {
					console.error(resp);
				}
			},
		});
	} else {
		validation.errors.forEach(err => {
			err.input.classList.add('error');

			const errDiv = document.createElement('div');
			errDiv.classList.add('error-msg');
			errDiv.innerText = err.msg;

			err.input.parentNode.parentNode.prepend(errDiv);
		});
	}
}

function onClickRemoveItem(e) {
	e.currentTarget.removeEventListener('click', onClickRemoveItem);
	Cart.removeItem(e.currentTarget.getAttribute('data-remove-item'));
}

function onUpdateBillingCountry() {
	updateStates(dom.billing, parseInt(dom.billing.countrySelect.value, 10));
}

function onUpdateShippingCountry() {
	updateStates(dom.shipping, parseInt(dom.shipping.countrySelect.value, 10));
}

export default {
	init,
};
