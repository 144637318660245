'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';

require('./utils/jquery.writedate.js');

function refresh() {

	$('[data-date-format]').writeDate({
		format: {
			fr: '%e %B %Y',
			en: '%B %e, %Y',
		},

		lang: ns.lang,
	});
}

export default {
	setup: () => {
		refresh();
		$('body').on('changed.lagrange.ajax', refresh);
	},
};
