import $ from 'jquery';
import { TweenMax, Power3 } from 'gsap';
import flatpickr from 'flatpickr';
import Promise from 'promise';
import moment from 'moment-timezone';
import { French } from 'flatpickr/dist/l10n/fr';
import ns from './Namespace';
import { scrollToElem } from './utils/scrollTo';
import { isNativeScrolling } from './AppSettings';

import BookingManager, { BOOKING_MANAGER_UPDATE, STATE_ALLOWED_COUNTRIES, PACKAGE_PREFIX } from './BookingManager';
import { 
	getEndpointUrl, 
	CREATE_APPOINTMENT,
} from './Api';
import TagManager from './TagManager';

let currentStep = 1;

const getLastDay = (y, m) => (new Date(Number(y), Number(m), 0)).getDate();
const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const dom = {};
const {
	treatment_informations,
} = ns;
let datepickers = [];
let previousTreatment;
let previousPackage;
const availableSchedules = [];

function init() {
	dom.form = document.querySelector('[data-reservation-form]');
	dom.formContainer = document.querySelector('[data-reservation-container]');
	if (!dom.form) return;

	BookingManager.init(true);
	setupDomReferences();
	TagManager.checkoutReservation();
	
	dom.validate_reservation_button.addEventListener('click', onConfirmReservation);
	dom.location.addEventListener('input', onChangeLocation);
	setupDatePicker();
	setupQuantities();

	dom.treatment.addEventListener('input', onChangeTreatment);
	dom.durations.forEach(x => x.addEventListener('change', onChangeDuration));
	dom.genders.forEach(x => x.addEventListener('input', onChangeGender));
	if (dom.is_duo) dom.is_duo.addEventListener('input', onChangeDuo);
	dom.countrySelect.addEventListener('change', onChangeCountry);
	dom.stateSelect.addEventListener('change', onChangeState);

	dom.confirm_treatment_button.addEventListener('click', onClickChooseSchedule);
	dom.back_treatment.addEventListener('click', onClickBackTreatment);
	dom.unavailable_modal.querySelector('[data-close-btn]').addEventListener('click', hideUnavailableModal);

	window.addEventListener(BOOKING_MANAGER_UPDATE, onUpdateManager);

	onChangeLocation();
	const defaultTreatmentId = dom.treatment.getAttribute('data-default');
	// console.log(defaultTreatmentId);
	if (defaultTreatmentId) BookingManager.setTreatment(defaultTreatmentId);
	updateClientInfos(1);
	onChangeCountry();
	onChangeTreatment();
}

function validateReservationData(data) {
	const validation = {
		isValid: true,
		errors: [],
	};

	// console.log(data);

	Array.concat(
		Array.from(dom.form.querySelectorAll('input[required]')),
		Array.from(dom.form.querySelectorAll('select[required]'))
	).forEach(input => {
		if (input.value === '' || input.value === undefined || input.value === null) {
			validation.isValid = false;
			validation.errors.push({
				msg: ns.lang === 'fr' ? 'Ce champ est requis.' : 'This field is required',
				input,
			});
		}
	});

	if (!/^([A-Z][0-9][A-Z]\s?[0-9][A-Z][0-9])|([0-9]{5})$/i.test(data.paymentInfos.zip)) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.form.querySelector('[name="postal_code"]'),
			msg: ns.lang === 'fr' ? 'Format de code postal incorrect: A1A 1A1 (CAN) ou 12345 (US).' : 'Incorreclty formatted postal code: A1A1A1 (CAN) or 12345 (US)',
		});
	}

	if (!/^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/i.test(data.customerInfos.phone)) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.form.querySelector('[name="phone_1"]'),
			msg: ns.lang === 'fr' ? 'Format de numéro de téléphone incorrect: 111-111-1111.' : 'Incorreclty formatted phone number: 111-111-1111',
		});
	}

	if (Number.isNaN(parseInt(data.paymentInfos.card_number, 10)) || data.paymentInfos.card_number.length !== 16) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.form.querySelector('[name="card_number"]'),
			msg: ns.lang === 'fr' ? 'Le numéro de carte de crédit doit être composé de 16 chiffres.' : 'Credit card number must be 16 digits',
		});
	}

	if (Number.isNaN(parseInt(data.paymentInfos.security_code, 10)) || data.paymentInfos.security_code.length !== 3) {
		validation.isValid = false;
		validation.errors.push({
			input: dom.form.querySelector('[name="card_cvv"]'),
			msg: ns.lang === 'fr' ? 'Le numéro de sécurité de crédit doit être composé de 3 chiffres.' : 'Security number mest be 3 digits',
		});
	}

	return validation;
}

function onError(res) {
	// console.log(res);
	if (res && res.error) {
		const errDiv = document.createElement('div');
		errDiv.classList.add('error-msg');
		errDiv.innerText = res.error.message;
		dom.backend_errors.appendChild(errDiv);

		dom.backend_errors.style.display = 'block';
	}

	dom.validate_reservation_button.addEventListener('click', onConfirmReservation);
	
	scrollToElem(dom.form, {
		offset: 100,
	});
}

function onConfirmReservation(e) {
	e.preventDefault();
	dom.validate_reservation_button.removeEventListener('click', onConfirmReservation);

	Array.from(dom.form.querySelectorAll('.error')).forEach(err => err.classList.remove('error'));
	Array.from(dom.form.querySelectorAll('.error-msg')).forEach(err => err.remove());

	const formData = new FormData(dom.form);
	const treatment = BookingManager.computeService(false);
	const pckg = BookingManager.getPackage();
	
	const expDateY = formData.get('card_exp_a');
	let expDateM = String(formData.get('card_exp_m'));
	const expDateD = getLastDay(expDateY, expDateM);
	if (expDateM.length < 2) expDateM = '0' + expDateM;
	// console.log(expDateY, expDateM, expDateD);
	const countryName = BookingManager.getCountry() ? Array.from(dom.countrySelect.querySelectorAll('option')).filter(o => parseInt(o.getAttribute('value'), 10) === BookingManager.getCountry())[0].innerText : null;

	let apiTreatment;
	let apiPckg;
	if (treatment) {
		apiTreatment = {
			id: treatment.booker_id,
			schedule: moment(BookingManager.getSchedule()).tz('America/New_York').format(),
			location: BookingManager.getLocation().booker_id,
		};
	} else if (pckg) {
		apiPckg = {
			id: pckg.booker_id,
			schedule: moment(BookingManager.getSchedule()).tz('America/New_York').format(),
			itinerary: BookingManager.getItinerary(),
			location: BookingManager.getLocation().booker_id,
		};
	}
	
	const reservationData = {
		nbGuests: BookingManager.getNbGuests(),
		treatment: apiTreatment,
		package: apiPckg,
		paymentInfos: {
			zip: formData.get('postal_code').trim(),
			name: formData.get('card_name'),
			card_number: formData.get('card_number').trim(),
			exp_date: moment(`${expDateY}-${expDateM}-${expDateD}`).tz('America/New_York').format(),
			security_code: formData.get('card_cvv'),
			card_type: capitalize(formData.get('card_type').toLowerCase()),
		},
	};

	if (BookingManager.getNbGuests() === 1) {
		if (reservationData.treatment) reservationData.treatment.staffGender = BookingManager.getGenders()[0]; //eslint-disable-line
		reservationData.customerInfos = {
			city: formData.get('city'),
			country: {
				id: formData.get('country'),
				name: countryName,
			},
			state: formData.get('province'),
			street1: formData.get('address_1'),
			street2: formData.get('address_2'),
			zip: formData.get('postal_code').trim(),
			email: formData.get('email_1'),
			first_name: formData.get('first_name_1'),
			last_name: formData.get('last_name_1'),
			phone: formData.get('phone_1'),
		};
	} else {
		reservationData.customers = [];
		const availableEmployees = BookingManager.getAvailabilities().filter(a => a.startDateTime === BookingManager.getSchedule())[0].availabilityItems;

		for (let n = 1; n < BookingManager.getNbGuests() + 1; n++) { //eslint-disable-line
			reservationData.customers.push({
				city: formData.get('city'),
				country: {
					id: formData.get('country'),
					name: countryName,
				},
				state: formData.get('province'),
				street1: formData.get('address_1'),
				street2: formData.get('address_2'),
				zip: formData.get('postal_code').trim(),
				email: formData.get(`email_${n}`),
				first_name: formData.get(`first_name_${n}`),
				last_name: formData.get(`last_name_${n}`),
				phone: formData.get(`phone_${n}`),
				employee: availableEmployees && availableEmployees[n - 1] && availableEmployees[n - 1].employeeId,
			});
		}
	}

	const validation = validateReservationData(reservationData);
	if (validation.isValid) {
		dom.processing_modal.classList.add('active');

		dom.frontend_errors.style.display = 'none';

		scrollToElem(dom.processing_modal, {
			offset: (($(window).height() - dom.processing_modal.clientHeight) / 2),
		});

		$.ajax({
			url: getEndpointUrl(CREATE_APPOINTMENT),
			method: 'POST',
			data: reservationData,
			dataType: 'JSON',
			success(response) {
				const res = (response && response.res) || response;
				console.log(response);
				dom.processing_modal.classList.remove('active');
				if (res.IsSuccess) {
					const reservationNumber = res.BookingNumber || (res.Appointment && res.Appointment.BookingNumber) || 'not specified';
					dom.confirmation_number.innerHTML = reservationNumber;
					TagManager.paymentReservation(reservationNumber);
					animateTo();
					dom.back_treatment.style.display = 'none';
				} else {
					onError(res);
				}
			},
			error(r) {
				console.log(r);
				console.log(r && r.responseText);
				onError(null);
			},
		});
	} else {

		dom.frontend_errors.style.display = 'block';

		validation.errors.forEach(err => {
			err.input.classList.add('error');

			const errDiv = document.createElement('div');
			errDiv.classList.add('error-msg');
			errDiv.innerText = err.msg;

			err.input.parentNode.parentNode.prepend(errDiv);
		});

		dom.validate_reservation_button.addEventListener('click', onConfirmReservation);
	}
}

function getIncludedLabel(isIncluded = false) {
	if (ns.lang === 'fr') {
		return isIncluded ? 'Incluse' : 'Non incluse';
	}
	return isIncluded ? 'Included' : 'Not included';
}

function getGendersLabel(pref = null) {
	if (pref === '1') return ns.lang === 'fr' ? 'Personnel masculin' : 'Male staff';
	if (pref === '2') return ns.lang === 'fr' ? 'Personnel féminin' : 'Female staff';
	return ns.lang === 'fr' ? 'Aucune préférence' : 'No preference';
}

function parseGenders(prefs) {
	const nbGuests = BookingManager.getNbGuests();
	const hasGender = BookingManager.getHasGender() && (nbGuests < 2);
	if (!hasGender) return 'N/A';
	if (nbGuests === 1) return getGendersLabel(prefs[0]);
	return prefs.reduce((html, v, i) => {
		if (i >= nbGuests) return html;
		return html + '<br>' + (ns.lang === 'fr' ? 'Client #' : 'Guest #') + (i + 1) + ': ' + getGendersLabel(v);
	}, '');
}

function onUpdateManager() {
	const treatment = BookingManager.getTreatment();
	const currentPackage = BookingManager.getPackage();

	if (treatment !== previousTreatment && treatment) {
		updateTreatmentDetails(treatment, null);
		previousTreatment = treatment;
		previousPackage = null;
	}
	if (currentPackage !== previousPackage && currentPackage) {
		updateTreatmentDetails(null, currentPackage);
		previousPackage = currentPackage;
		previousTreatment = null;

	}

	updateTreatments();
	updateDurations();
	updatePrice();
	updateDuo();

	dom.summary.location.innerHTML = BookingManager.getLocation().name;
	dom.summary.treatment.innerHTML = (treatment && treatment.name_lng) || (currentPackage && (currentPackage.name_lng || currentPackage.name));
	dom.summary.nbguests.innerHTML = BookingManager.getNbGuests();
	dom.summary.pref.innerHTML = parseGenders(BookingManager.getGenders());

	const dur = BookingManager.getDuration();
	dom.summary.durationContainer.style.display = dur ? '' : 'none';
	dom.summary.duration.innerHTML = dur + ' mins';
}

function onChangeCountry() {
	const country = parseInt(dom.countrySelect.options[dom.countrySelect.selectedIndex].value, 10);
	BookingManager.setCountry(country);
	BookingManager.setState(null);
	updateStates(country);
}

function onChangeState(e) {
	const state = parseInt(e.currentTarget.value, 10);
	BookingManager.setState(Number.isNaN(state) ? null : state);
}

function onChangeLocation() {
	BookingManager.setLocation(dom.location.value);
}

function onChangeTreatment() {

	if (dom.treatment.value) BookingManager.setTreatment(dom.treatment.value);

	const nbGuests = BookingManager.getNbGuests();
	dom.nbGuests.forEach(x => {
		// console.log(nbGuests, Number(x.value));
		if (Number(x.value) === nbGuests) {
			x.setAttribute('checked', 'true');
			x.checked = true;
		} else {
			x.removeAttribute('checked');
			x.checked = false;
		}
	});	
	
	toggleGenderPreferences();
	validateTreatment();
}

//some treatments don't allow to choose gender of personnel
function toggleGenderPreferences() {
	const nbGuests = dom.nbGuests.find(x => x.checked).value;
	//more than one, we cannot send gender preferences to booker
	const hasGender = BookingManager.getHasGender() && (nbGuests < 2);

	// console.log(hasGender);
	dom.genderPreferenceFields.forEach((field, i) => {
		const isActive = (i < nbGuests) && hasGender;
		field.style.display = isActive ? '' : 'none';
	});

	//if no possibility, set all selects to default
	if (!hasGender) {
		dom.genders.forEach((g) => {
			g.value = '';
		});
	}

}

function onChangeDuration() {
	BookingManager.setDuration(dom.durations.find(x => x.checked).value);
	validateTreatment();
}

function onChangeGender() {
	BookingManager.setGenders(dom.genders.reduce((c, g) => {
		// console.log(g.value);
		if (g.value) c.push(g.value);
		return c;
	}, []));
	validateTreatment();
}

function onChangeDuo() {
	BookingManager.setDuo(dom.is_duo && dom.is_duo.checked);
	validateTreatment();
}


function onChangeNbGuests() {
	const nbGuests = dom.nbGuests.find(x => x.checked).value;
	BookingManager.setNumGuests(nbGuests);

	toggleGenderPreferences();

	// console.log(BookingManager.canDuo());
	dom.clientNum.style.display = (nbGuests === 1) ? 'none' : '';
	
	updateClientInfos(nbGuests);
	validateTreatment();
	updateDuo();
}

function updateDuo() {
	const canDuo = BookingManager.canDuo();
	dom.is_duo_wrapper.style.display = canDuo ? '' : 'none';
	if (!canDuo && dom.is_duo) dom.is_duo.checked = false;
	// console.log(canDuo, dom.is_duo.checked);
}

function onClickChooseSchedule() {
	// console.log(BookingManager.debugParams());
	cleanSchedules();
	TagManager.addReservationToCart(BookingManager.computeServiceOrPackage());
	if (!dom.confirm_treatment_button.classList.contains('disabled')) {
		// updateSchedule().then(() => {
		updateDates().then(() => {
			updateSchedule();
		}).catch(() => {
			showUnavailableModal();
		});
		animateTo(1);
		// });
	}
}

function onClickBackTreatment() {
	animateTo(-1);
}

function onSelectSchedule(e) {
	dom.schedules.forEach((schedule) => {
		schedule.querySelector('[data-select-schedule]').classList.remove('active');
	});
	e.currentTarget.classList.add('active');

	const items = e.currentTarget.skyspa_items;

	// console.log(items);

	BookingManager.setSchedule(e.currentTarget.getAttribute('data-select-schedule'), items);
	TagManager.checkoutReservationProgress(BookingManager.computeServiceOrPackage());
	animateTo(1);
}

function animateTo(dir = 1) {
	currentStep += dir;
	if (dir === 1) {
		switch (currentStep) {
			case 2: animatePanels(dom.treatment_panel, dom.schedule_panel, dom.infos, dom.reservation_summary); break;
			case 3: animatePanels(dom.schedule_panel, dom.guestinfo_panel); break;
			case 4: animatePanels(dom.guestinfo_panel, dom.confirmation_panel); break;
			default: break;
		}
	} else if (dir === -1) {
		switch (currentStep) {
			case 1: animatePanels(dom.schedule_panel, dom.treatment_panel, dom.reservation_summary, dom.infos); break;
			case 2: animatePanels(dom.guestinfo_panel, dom.schedule_panel); break;
			default: break;
		}
	}
}

function animatePanels(panel1, panel2, subPanel1, subPanel2) {
	scrollToElem(dom.formContainer, {
		container: isNativeScrolling ? $('main') : document.querySelector('.scroll-content'),
		offset: isNativeScrolling ? -100 : 100,
	});

	TweenMax.to(panel1, 0.6, {
		opacity: 0,
		ease: Power3.easeInOut,
		delay: 0.3,
		onComplete: () => {
			const h = dom.form.getBoundingClientRect().height;
			TweenMax.set(panel1, { display: 'none' });
			TweenMax.set(panel2, { display: '' });
			TweenMax.set(dom.form, { height: '' });
			TweenMax.fromTo(dom.form, 0.6, { height: h }, { height: dom.form.getBoundingClientRect().height, ease: Power3.easeInOut });
			TweenMax.fromTo(panel2, 0.6, { opacity: 0 }, {
				opacity: 1,
				delay: 0.6,
				ease: Power3.easeInOut,
				onComplete: () => {
					TweenMax.set(dom.form, { height: '' });
				},
			});
		},
	});

	if (subPanel1 && subPanel2) {
		TweenMax.to(subPanel1, 0.6, {
			opacity: 0, 
			y: 20,
			onComplete: () => {
				TweenMax.set(subPanel1, { display: 'none' });
				TweenMax.set(subPanel2, { display: '' });
				TweenMax.fromTo(subPanel2, 0.6, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: Power3.easeInOut });
			},
		});
	}/** */
}

// Make sure that we have selected a valid treatment, otherwise cannot choose times
function validateTreatment() {
	const dur = BookingManager.getDuration();
	const currentPackage = BookingManager.getPackage();
	const treatment = BookingManager.getTreatment();
	const isEnabled = currentPackage || (dur && treatment);
	const fcn = isEnabled ? 'remove' : 'add';
	dom.confirm_treatment_button.classList[fcn]('disabled');
}

function updateStates(country) {

	if (STATE_ALLOWED_COUNTRIES.indexOf(country) > -1) {
		dom.stateRow.style.display = 'block';
		dom.stateSelect.setAttribute('required', 'required');

		//loop dans options, montre celles du pays selected
		// console.log(type.stateSelect.options);
		const options = Array.from(dom.stateSelect.children);
		options.forEach(o => {
			
			const isShow = Number(o.getAttribute('data-country-id')) === country;
			const isSelected = o.hasAttribute('default-selected') && isShow;
			o.style.display = isShow ? '' : 'none';
			if (isSelected) {
				o.setAttribute('selected', true);
			} else {
				o.removeAttribute('selected');
			}
			if (isShow) {
				o.removeAttribute('disabled');
			} else {
				o.setAttribute('disabled', true);
			}
			// console.log(o.getAttribute('disabled'));
		});
	

	} else {
		dom.stateRow.style.display = 'none';
		dom.stateSelect.removeAttribute('required');
		dom.stateSelect.value = null;
	}

}

function updateTreatmentDetails(treatment, pckg) {
	let title = '';
	let loadedData = Promise.resolve();
	let ajaxUrl;
	if (treatment) {
		const currentTreatmentInfo = treatment_informations.find(x => {
			// console.log(x.treatment_group_id, BookingManager.getTreatment().id);
			return Number(x.treatment_group_id) === Number(treatment.id);
		});
		// console.log(currentTreatmentInfo);
		const { id, name } = currentTreatmentInfo;
		ajaxUrl = `/?t=treatment_information&i=${id}&l=${ns.lang}`;
		title = `<h1>${name}</h1>`;
	} else if (pckg) {
		const { information_id, name } = pckg;
		ajaxUrl = `/?t=package_information&i=${information_id}&l=${ns.lang}`;
		title = `<h1>${name}</h1>`;
	}

	if (ajaxUrl) {
		
		loadedData = new Promise((resolve, reject) => {
			$.ajax({
				url: ajaxUrl,
				success: data => resolve(data),
				error: reject,
			});
		});
	}

	const doneAnimating = new Promise((resolve) => {
		TweenMax.to(dom.infos, 0.6, {
			opacity: 0,
			y: 20,
			onComplete: resolve,
		});
	});
	

	Promise.all([doneAnimating, loadedData]).then((args) => {
		const data = args[1];
		const infos = $(data).find('.treatment-info');
		infos.children().remove('h1, h2, h3, h4, h5, h6');
		dom.infos.innerHTML = title + infos[0].innerHTML;

		TweenMax.to(dom.infos, 0.6, { opacity: 1, y: 0 });
	});
	
}

function updateTreatments() {
	// console.log('update treatment');
	const current = BookingManager.getTreatment();
	// console.log(current);
	const defaultTreatmentId = current && Number(current.id);
	dom.treatment.innerHTML = '';
	//default peut provenir du get
	BookingManager.getTreatments(true).sort((a, b) => a.order - b.order).forEach((treatment) => {
		const selected = (defaultTreatmentId === Number(treatment.id)) ? 'selected' : '';
		dom.treatment.innerHTML += `<option value="${treatment.id}" ${selected}>${treatment.name_lng}</option>`;
	});

	//also adds packages
	const currentPackage = BookingManager.getPackage();
	const packs = BookingManager.getPackages(true);
	// console.log(currentPackage);
	packs.forEach((pack) => {
		const selected = (currentPackage && currentPackage.id === pack.id) ? 'selected' : '';
		dom.treatment.innerHTML += `<option value="${PACKAGE_PREFIX}${pack.id}" ${selected}>${pack.name}</option>`;
	});

}

function updateDurations() {
	const selectedDuration = BookingManager.getDuration();
	const durations = BookingManager.getDurations();
	// console.log(durations);

	const durationsDisplay = durations.length === 0 ? 'none' : '';
	dom.durationsContainer.style.display = durationsDisplay;

	dom.durations.forEach(duration => {
		if (durations.find(d => d === duration.value)) {
			if (duration.value === selectedDuration) {
				duration.checked = true;
			}
			duration.disabled = false;
		} else {
			if (duration.checked) {
				duration.checked = false;
			}
			duration.disabled = true;
		}
	});
}

function updateDates() {
	const onDatesReceived = BookingManager.updateDates();
	// console.log(dates);
	return new Promise((resolve, reject) => {
		if (onDatesReceived) {
			const previousDate = datepickers[0].selectedDates && datepickers[0].selectedDates[0];
			dom.confirm_treatment_button.classList.add('disabled');
	
			datepickers.forEach(dp => {
				dp.set('enable', [() => false]);
				dp.input.disabled = true;
				dom.date_loadings.forEach(l => l.classList.add('active'));
				dp.setDate(null);
			});
	
			window.datepickers = datepickers;
	
			onDatesReceived.then((availability) => {
				// console.log(availability);
				if (!availability || Object.keys(availability).length === 0 || availability.length === 0) {
					showUnavailableModal();
					reject();
				} else {
					// console.log(availability);

					datepickers.forEach(dp => dp.set('enable', availability));
					dom.confirm_treatment_button.classList.remove('disabled');
	
					let availableDate = availability[0];
					if (previousDate) {
						availableDate = availability.find(date => moment(date).isSame(previousDate, 'day')) || availableDate;
					}
					// console.log(previousDate);
					// console.log(availableDate);
					
					datepickers.forEach(dp => {
						dp.setDate(availableDate);
						dp.input.disabled = false;
						dom.date_loadings.forEach(l => l.classList.remove('active'));
					});
					// console.log(availableDate);

					BookingManager.setDate(availableDate);
					dom.summary.date.innerHTML = moment(availableDate).format('YYYY / MM / DD');
					// console.log(dom.summary);
					resolve();
				}
			}).catch((e) => {
				// console.error(e);

				if (e && e.type !== 'no-result') {
					console.error(e);
				}
				reject();
			});
		} else {
			resolve();
		}
	});
}

function updateSchedule() {
	cleanSchedules();
	return BookingManager.updateSchedule().then(result => {
		console.log(result);
		if (result) {
			setupSchedule(result);
		}
	}).catch(e => console.error(e));
}

function updateClientInfos(num) {
	// console.log(num);
	dom.client_infos.forEach((client, i) => {
		// console.log(i);
		if (i === 0) {
			client.querySelector('[data-client-num]').style.display = (num === 1) ? 'none' : '';
		}
		if (i < num) {
			client.style.display = '';

			client.querySelector(`[name="first_name_${i + 1}"]`).setAttribute('required', 'required');
			client.querySelector(`[name="last_name_${i + 1}"]`).setAttribute('required', 'required');
			client.querySelector(`[name="email_${i + 1}"]`).setAttribute('required', 'required');
			client.querySelector(`[name="phone_${i + 1}"]`).setAttribute('required', 'required');
		} else {
			client.style.display = 'none';
			client.querySelector(`[name="first_name_${i + 1}"]`).removeAttribute('required');
			client.querySelector(`[name="last_name_${i + 1}"]`).removeAttribute('required');
			client.querySelector(`[name="email_${i + 1}"]`).removeAttribute('required');
			client.querySelector(`[name="phone_${i + 1}"]`).removeAttribute('required');
		}
	});
}

function showUnavailableModal() {
	dom.unavailable_modal.classList.add('active');
}

function hideUnavailableModal() {
	dom.unavailable_modal.classList.remove('active');
}

function updatePrice() {
	let price = BookingManager.getPrice(false);
	if (ns.lang === 'fr') {
		price = `${price}$`;
	} else {
		price = `$${price}`;
	}
	dom.price.innerHTML = price;
	dom.summary.price.innerHTML = price;
}


function setupDomReferences() {
	const { form } = dom;

	dom.validate_reservation_button = form.querySelector('[data-validate-reservation]');
	dom.location = form.querySelector('[data-location]');
	dom.treatment_panel = form.querySelector('[data-panel-treatment]');
	dom.schedule_panel = form.querySelector('[data-panel-schedule]');
	dom.guestinfo_panel = form.querySelector('[data-panel-guestinfo]');
	dom.confirmation_panel = form.querySelector('[data-panel-confirmation]');
	dom.confirmation_number = form.querySelector('[data-confirmation-number]');

	dom.treatment = form.querySelector('[data-treatment]');
	dom.durations = Array.from(form.querySelectorAll('input[name=duration]'));
	dom.durationsContainer = form.querySelector('[data-durations-container]');
	dom.genders = Array.from(form.querySelectorAll('select[name^=therapist_gender]'));
	dom.price = form.querySelector('[data-price]');
	dom.is_duo = form.querySelector('input[name=duo]');
	dom.unavailable_modal = form.querySelector('[data-unavailable-modal]');
	dom.confirm_treatment_button = form.querySelector('[data-choose-schedule]');
	dom.schedules_ctn = form.querySelector('[data-schedules-ctn]');
	dom.date_loadings = Array.from(form.querySelectorAll('[data-loading-anim]'));

	dom.client_infos = Array.from(form.querySelectorAll('[data-client-info]'));
	
	dom.back_treatment = document.querySelector('[data-back-treatment]');
	dom.reservation_summary = document.querySelector('[data-reservation-summary]');
	dom.infos = document.querySelector('[data-ajax-reservation-ctn]');

	dom.summary = {};
	dom.summary.treatment = document.querySelector('[data-summary-treatment]');
	dom.summary.pref = document.querySelector('[data-summary-pref]');
	dom.summary.price = document.querySelector('[data-summary-price]');
	dom.summary.date = document.querySelector('[data-summary-date]');
	dom.summary.nbguests = document.querySelector('[data-summary-nbguests]');
	dom.summary.duration = document.querySelector('[data-summary-duration]');
	dom.summary.durationContainer = document.querySelector('[data-summary-duration-container]');
	dom.summary.location = document.querySelector('[data-summary-location]');

	dom.countrySelect = form.querySelector('[data-country-select]');
	dom.stateSelect = form.querySelector('[data-state-select]');
	dom.stateRow = form.querySelector('[data-state-row]');
	dom.processing_modal = form.querySelector('[data-processing-modal]');
	dom.processing_modal_button = dom.processing_modal.querySelector('[data-close-btn]');
	dom.backend_errors = form.querySelector('[data-backend-errors]');
	dom.frontend_errors = form.querySelector('[data-frontend-errors]');
}

function setupDatePicker() {
	// dom.datepicker = dom.form.querySelector('[data-datepicker]');
	dom.datepickerSchedule = dom.form.querySelector('[data-datepicker-schedule]');
	const startSunday = { firstDayOfWeek: 0 };
	const locale = ns.lang === 'fr' ? { ...French, ...startSunday } : startSunday;

	datepickers = [/*dom.datepicker, */dom.datepickerSchedule].map((datepicker) => {
		return flatpickr(datepicker, {
			static: true,
			defaultDate: Date.now(),
			enable: [() => false],
			locale,
			allowInput: false,
			onChange: (date, dateStr) => {
				BookingManager.setDate(dateStr);
				dom.summary.date.innerHTML = moment(dateStr).format('YYYY / MM / DD');
				syncDatePickers(dateStr);

				if (datepicker === dom.datepickerSchedule) {
					updateSchedule();
				}
			},
		});
	});
}

function syncDatePickers(dates) {
	datepickers.forEach(dp => dp.setDate(dates, false));
}

function setupQuantities() {
	const { form } = dom;

	dom.nbGuests = Array.from(form.querySelectorAll('input[name=num-guests]'));
	dom.is_duo_wrapper = form.querySelector('[data-is-duo]');
	dom.clientNum = form.querySelector('[data-client-num]');
	dom.genderPreferenceFields = form.querySelectorAll('[data-preference]');

	dom.nbGuests.forEach(x => x.addEventListener('change', onChangeNbGuests));
}

function cleanSchedules() {
	if (dom.schedules) {
		dom.schedules.forEach((schedule) => {
			const button = schedule.querySelector('[data-select-schedule]');
			button.removeEventListener('click', onSelectSchedule);
		});
	
		dom.schedules_ctn.innerHTML = '';
		dom.schedules = [];
	}
}

function setupSchedule(times) {
	dom.schedules = times.map((schedule) => {
		const duration = BookingManager.getDuration();
		const start = moment(schedule.startDateTime);
		const end = (schedule.endDateTime && moment(schedule.endDateTime)) || start.add(duration, 'minutes');
		const div = document.createElement('div');
		div.classList.add('row', 'schedule-time');
		// console.log(schedule);

		//for packages, we need to remember all contents schedules to send to booker
		const items = schedule.availabilityItems && schedule.availabilityItems.map(item => {
			return {
				id: item.serviceId,
				schedule: item.startDateTime,
			};
		});

		const selectLabel = ns.lang === 'fr' ? 'Sélectionner' : 'Select';
		div.innerHTML = `
			<div class="col-sm-3 col-6 time" data-start>${start.format('HH:mm')}</div>
			<div class="col-sm-6 col-12 btn-ctn"><div class="button" data-select-schedule="${schedule.startDateTime}"><span>${selectLabel}</span></div></div>`;
		dom.schedules_ctn.appendChild(div);

		const btn = div.querySelector('[data-select-schedule]');
		btn.skyspa_items = items;
		btn.addEventListener('click', onSelectSchedule);
		return div;
	});
}

export default {
	init,
};
