'format es6';
'use strict';

import {
	ELEM_BOTTOM,
	ELEM_TOP,
	ELEM_CENTER,
	SCREEN_BOTTOM,
	SCREEN_TOP,
	SCREEN_CENTER,
} from '../lagrange/animation/Animator';

export const Animations = {
	get(isMobile) {
		const animations = {};
		
		animations.light_parallax = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					y: isMobile ? '0vh' : '5vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: isMobile ? '0vh' : '0vh',
				},
			],
		};

		return animations;
	},
};
