'format es6';
'use strict';

import $ from 'jquery';
import { scrollToElem } from './utils/scrollTo';
import { isNativeScrolling } from './AppSettings';

import Scrollbar from 'smooth-scrollbar';

let anchorMenu;
let sb;


function onAnchorClick(e) {
	e.preventDefault();
	
	const targetEl = e.currentTarget;
	const targetHref = targetEl.getAttribute('href').substring(1);
	const target = document.getElementById(targetHref);

	if (target) {
		if (!isNativeScrolling) {
			sb.scrollIntoView(target);
		} else {
			scrollToElem(target, { container: $('main') });
		}
	}
}

export default {
	init() {
		const main = document.querySelector('[data-main-scrollbar]');
		const anchorLinks = document.querySelectorAll('a[href^="#"]');

		if (!isNativeScrolling && anchorLinks) {
			sb = Scrollbar.get(main);
		}
		
		if (anchorLinks) {
			anchorLinks.forEach((curr) => {
				curr.addEventListener('click', onAnchorClick);
			});
		}
	},
};
