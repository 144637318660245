'format es6';
'use strict';

import $ from 'jquery';
import { docReady } from './utils/docReady';
import selectric from '../../node_modules/selectric';

const MAX_FILE_SIZE = 2; // In Mo
let careerForm;

function getFileInfos(input) {
	const inputFile = input[0].files[0];
	const inputExt = input.val().split('.').pop();
	const size = inputFile ? parseFloat((input[0].files[0].size / 1024 / 1024).toFixed(2)) : 0;

	if ((inputExt === 'pdf' || inputExt === 'docx' || inputExt === 'doc') && size < MAX_FILE_SIZE) {
		return true;
	}

	return false;
}

function validForm(e) {
	e.preventDefault();
	careerForm[0].removeEventListener('submit', validForm);
	careerForm.addClass('is-validating');

	const formToValidate = careerForm;
	const errorMessage = $('.error-message', formToValidate);
	
	const inputs = formToValidate.find('input, select, textarea').toArray();
	
	const isValid = inputs.reduce((isAllValid, el) => {
		const input = $(el);
		let filesInfo;
		
		if (input.attr('type') === 'file') {
			filesInfo = getFileInfos(input);
		}
		
		let isCurValid = el.checkValidity();

		if (!isCurValid) {
			if (input.val() === '' && input.attr('required')) {
				input.addClass('error');
			}
		}

		if (input.attr('type') === 'tel' && !input.val().match(/^(?=.*?[1-9])[0-9()-]+$/)) {
			input.addClass('error');
			isCurValid = false;
		}

		if (input.attr('type') === 'file' && !filesInfo) {
			input.addClass('error');
			isCurValid = false;
		}

		return isAllValid && isCurValid;
	}, true);

	if (isValid) {
		sendEmail(formToValidate);
	} else {
		setTimeout(() => {
			errorMessage.slideDown();
			careerForm.removeClass('is-validating');
			careerForm[0].addEventListener('submit', validForm);
		}, 1000);
	}
}

function sendEmail(form) {
	const contact = form;
		
	const successMessage = $('.success-message');
	const errorMessage = $('.error-message', contact);
	errorMessage.slideUp();
	
	const formData = new FormData(); // Currently empty
	const fieldsValues = contact.serializeArray();
	const cvField = contact.find('[data-cv-file]')[0];
	const cvFile = cvField.files[0];
	const cvFileName = 	cvField.value.split('\\').pop();

	fieldsValues.forEach((field) => {
		formData.append(field.name, field.value);
	});

	formData.append('careers[cv]', cvFile, cvFileName);

	$.ajax({
		url: contact.attr('action'),
		data: formData,
		cache: false,
		contentType: false,
		processData: false,
		method: 'POST',
		type: 'POST',
		success: (data) => {
			if (data.error) {
				// errorMessage.slideDown();
			} else {
				contact.slideUp();
				successMessage.slideDown();

				const dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'forms',
					eventAction: 'submitted',
					eventLabel: 'career',
				});

				contact[0].reset();
			}
		},
	}).catch((err) => { console.error(err); });
}

function fillForm(e) {
	const clicked = $(e.currentTarget);
	const clickedVal = clicked.attr('data-apply');
	const ddOpening = $('[data-opening]');

	ddOpening.val(clickedVal).trigger('change.skyspa.careerOpening');
}

export default {
	init() {
		careerForm = $('[data-career-form]');
		if (careerForm.length === 0) return;

		const ddOpening = $('[data-opening]');
		const ddCategory = $('[data-spontaneous-cat]');
		const applyBtn = $('[data-apply]');

		ddOpening.on('change.skyspa.careerOpening', (e) => {
			const selected = $(e.currentTarget).find(':selected');
			
			if (selected.is('#spontaneous')) {
				ddCategory.addClass('show');
			} else {
				ddCategory.removeClass('show');
				ddCategory.find('select').val('');
			}
		}).trigger('change.skyspa.careerOpening');

		applyBtn.on('click', fillForm);

		careerForm.find('input[type=file]').on('change', (e) => {
			const input = $(e.currentTarget);
			let fileName = input.val();
			fileName = fileName.split('\\').pop();
			$(e.currentTarget).siblings('label').find('span').html(fileName);
		});

		careerForm[0].addEventListener('submit', validForm);
	},
};
