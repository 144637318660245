
function CustomEvent(event, params) {
	const parsedParams = params || { bubbles: false, cancelable: false, detail: undefined };
	const evt = document.createEvent('CustomEvent');
	evt.initCustomEvent(event, parsedParams.bubbles, parsedParams.cancelable, parsedParams.detail);
	return evt;
}

CustomEvent.prototype = window.Event.prototype;

export default CustomEvent;
