import { French } from 'flatpickr/dist/l10n/fr';
import flatpickr from 'flatpickr';

import ns from './Namespace';
import BookingManager, { BOOKING_MANAGER_UPDATE, PACKAGE_PREFIX } from './BookingManager';

const dom = {};

let initialSetup = true;

function init() {
	dom.form = document.querySelector('[data-quick-reservation]');
	if (!dom.form) return;

	BookingManager.init();
	BookingManager.setLocation(ns.location_id.toString(), false);

	setupDomReferences();
	updateTreatments();

	dom.treatment.addEventListener('change', onChangeTreatment);
	dom.nbguests.addEventListener('change', onChangeNbGuests);

}


function setupDomReferences() {
	dom.treatment = document.querySelector('[data-quick-treatment]');
	dom.nbguests = document.querySelector('[data-quick-num-guests]');
	dom.confirm_button = document.querySelector('[data-quick-confirm]');
}

function updateTreatments() {
	const current = BookingManager.getTreatment();
	let bypass = false;

	if (initialSetup) {
		bypass = true;
		initialSetup = false;
	}

	dom.treatment.innerHTML = `<option value="" selected disabled>${window.skyspa.quick_reserv_tx.soin_reserve}</option>`;
	BookingManager.getTreatments().sort((a, b) => a.order - b.order).forEach((treatment) => {
		const selected = (current && current.id === treatment.id && !bypass) ? 'selected' : '';
		dom.treatment.innerHTML += `<option value="${treatment.id}" ${selected}>${treatment.name_lng}</option>`;
	});

	const packs = BookingManager.getPackages(true);
	// console.log(packs);
	packs.forEach((pack) => {
		dom.treatment.innerHTML += `<option value="${PACKAGE_PREFIX}${pack.id}">${pack.name}</option>`;
	});

}

function onChangeTreatment() {
	dom.treatment.parentNode.classList.remove('default');
	onUpdateManager();
}

function onChangeNbGuests() {
	dom.nbguests.parentNode.classList.remove('default');
	onUpdateManager();
}

function enableBtn() {
	dom.confirm_button.classList.remove('disabled');
	dom.confirm_button.disabled = false;
}

function onUpdateManager() {
	if (dom.nbguests.value > 0 && dom.treatment.value) {
		enableBtn();
	}
}

export default {
	init,
};
